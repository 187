import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';
import React from 'react';

import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import NextAvailableBadge from 'professional/components/NextAvailableBadge.js';
import {ConcatenatedText} from '@HealthShareAU/hs-component-library';
import {BP, BP_FHIR} from 'core/constants';

@observer
export default class IntegratedProfilePracticeList extends React.Component {
    static contextType = ProfileStoreContext;
    static propTypes = {
        practices: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                address: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                phone: PropTypes.shape({
                    number: PropTypes.string.isRequired,
                }),
                praticeUrl: PropTypes.string,
            }),
        ),
    };

    constructor(props) {
        super(props);
    }

    handlePracticeChange(e, index) {
        // prevent click on tooltip
        let isTooltip = false;
        const tooltip = document.querySelectorAll('.tooltip');
        if (!!tooltip) {
            Array.from(tooltip).map((tooltip) => {
                if (tooltip.contains(e.target)) {
                    isTooltip = true;
                }
            });
        }
        if (!isTooltip) {
            this.context.updateStore({selectedPractice: index});
        }
    }

    renderPracticeHeader() {
        const {practices} = this.props;
        const {isRetired} = this.context;
        const practiceLength = practices?.length || 0;
        const isMutiLocation = practiceLength > 1 ? 'locations' : 'location';
        const isValidLength = practiceLength >= 1;
        let practiceLocationHeader = `Write referral${
            isValidLength
                ? ` (${practiceLength} practice ${isMutiLocation})`
                : ''
        }:`;

        if (isRetired) {
            practiceLocationHeader = `Previous practice ${isMutiLocation}:`;
        }

        return <h2>{practiceLocationHeader}</h2>;
    }

    renderAddress(address) {
        const splitAddress = address.split(',').slice(0, -1);
        return splitAddress.map((value, index) => (
            <span key={index}>{`${value}${
                index < splitAddress.length - 1 ? ',' : ''
            }`}</span>
        ));
    }

    render() {
        const {practices} = this.props;
        const {selectedPractice, isRetired, client} = this.context;
        const locationBtnClass = `practice-location${
            isRetired ? '-hidden' : ''
        }`;
        const isBPClient = client === BP || client === BP_FHIR;

        return (
            <div className="write question">
                {this.renderPracticeHeader()}
                <ul className="options">
                    {practices.map((practice, index) => {
                        const isPureTelehealth =
                            practice.offersTelehealth &&
                            practice.locationTelehealth === TELEHEALTH_ONLY;
                        return (
                            <li key={index}>
                                <div
                                    className={locationBtnClass}
                                    onClick={(e) =>
                                        this.handlePracticeChange(e, index)
                                    }
                                >
                                    <input
                                        checked={selectedPractice === index}
                                        name="location"
                                        type="radio"
                                        readOnly
                                    />
                                    {!isRetired && <Icon name="radio" />}
                                    <strong>
                                        {isPureTelehealth ? (
                                            `${practice.name}`
                                        ) : (
                                            <>
                                                {`${practice.localityName} ${practice.localityState} - `}
                                                <span>{`${practice.name}`}</span>
                                            </>
                                        )}
                                    </strong>
                                    <address>
                                        {isPureTelehealth
                                            ? 'Nation-wide online and telephone consultations'
                                            : this.renderAddress(
                                                  practice.address,
                                              )}
                                    </address>
                                    {!!practice.referralEmail && (
                                        <dl>
                                            <dt>
                                                <Icon name="mail" />
                                            </dt>
                                            <dd className="referral-email">
                                                <ConcatenatedText
                                                    expandText={'...'}
                                                    width={
                                                        isBPClient ? 340 : 480
                                                    }
                                                    text={
                                                        practice.referralEmail
                                                    }
                                                />
                                            </dd>
                                        </dl>
                                    )}
                                    <NextAvailableBadge
                                        hasIcon={true}
                                        practices={[practice]}
                                    />
                                    {!!practice.phones.length && (
                                        <dl>
                                            <dt>
                                                <Icon name="phone" />
                                            </dt>
                                            <dd>
                                                {practice.phones[0]?.number}
                                            </dd>
                                            {practice.practiceUrl && (
                                                <Link
                                                    href={practice.practiceUrl}
                                                >
                                                    {'More info'}
                                                    <Icon name="arrow" />
                                                </Link>
                                            )}
                                        </dl>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
