import {PropTypes as MobXPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import hotjar from 'hotjar.js';

import analytics from 'analytics.js';
import throttleDecorator from 'common/decorators/throttleDecorator.js';
import HealthFundPicker from 'core/components/HealthFundPicker.js';

export default class FundSelection extends React.Component {
    static propTypes = {
        formLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
        isEnhanced: PropTypes.bool,
        isReferrals: PropTypes.bool,
        profileId: PropTypes.number.isRequired,
        showNoInsurance: PropTypes.bool,
        updateSelectedFund: PropTypes.func,
    };

    @throttleDecorator(500)
    updateSelectedFund(hf) {
        const {isEnhanced} = this.props;
        const fundId = hf?.id || null;
        const profileType = isEnhanced ? 'pro' : 'non-pro';
        const healthFundTag = fundId
            ? `health-fund-${fundId}`
            : 'no-health-fund';
        const trigger = `profile-${profileType}-${healthFundTag}`;
        this.trackHealthFundSelect(fundId);
        hotjar('trigger', trigger);
        this.props.updateSelectedFund(fundId || '0');
    }

    trackHealthFundSelect(fundId) {
        const {profileId} = this.props;
        analytics.track('healthFundSelect', {
            practitionerId: profileId,
            hsHealthFundId: fundId ? parseInt(fundId) : null,
        });
    }

    render() {
        const {isReferrals} = this.props;

        let noInsuranceText = "I don't have private health insurance";

        if (isReferrals) {
            noInsuranceText = "They don't have private health insurance";
        }

        return (
            <HealthFundPicker
                handleSelectHealthFund={this.updateSelectedFund}
                initialOptionText={noInsuranceText}
                stopAutoScroll={true}
            />
        );
    }
}
