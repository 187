import {observer} from 'mobx-react';
import React from 'react';
import autobind from 'common/decorators/autobind.js';
import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import {
    EVENT_INTEGRATED_PROFILE,
    SEND_PROFILE_CHECKBOX,
    PROFILE_SMS_MOBILE,
    SEND_SMS_TEXT,
    FREE_SMS_TEXT,
    IS_SWITCH_ON,
    SHOW_REFERRAL_BTN,
} from 'core/constants.js';
import http from 'http.js';
import {
    BPSecureMessageButton,
    BPWriteReferralButton,
    MDWriteReferralButton,
    ZMWriteReferralButton,
    HSReferralButton,
} from 'professional/components/ReferralButtons.js';

import {Switch} from '@HealthShareAU/hs-component-library';
import {
    sessionStorageGetItem,
    sessionStorageSetItem,
    sessionStorageRemoveItem,
} from 'core/utils.js';
import {
    formatAustralianMobileNumber,
    isValidAustralianMobileNumber,
} from 'utils/validation.js';
import {getCopyData} from 'core/referrals.js';

@observer
export default class IntegratedProfileReferralButtons extends React.Component {
    static contextType = ProfileStoreContext;

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            isSmsToggleOn: sessionStorageGetItem(IS_SWITCH_ON) === 'true',
        };
    }

    componentDidMount() {
        const mobileNumberStorage = sessionStorageGetItem(PROFILE_SMS_MOBILE);

        if (
            mobileNumberStorage &&
            isValidAustralianMobileNumber(mobileNumberStorage)
        ) {
            this.setState({
                phoneNumber: mobileNumberStorage,
            });
        } else {
            sessionStorageRemoveItem(PROFILE_SMS_MOBILE);
        }
    }

    get isValidMobileNumber() {
        return isValidAustralianMobileNumber(this.state.phoneNumber);
    }

    @autobind
    async sendSMS() {
        const {isSmsToggleOn, phoneNumber} = this.state;
        const {profileId} = this.context;

        if (!isSmsToggleOn) {
            return;
        }
        try {
            const url = `/api/professionals/v1/${profileId}/send-practitioner-profile/`;
            const data = {
                'mobile_number': phoneNumber,
            };
            await http.post({
                url,
                data,
            });
        } catch (error) {
            throw new Error(`Send practitioner profile API returned ${error}`);
        }
    }

    handlePracticeChange(index) {
        this.context.updateStore({selectedPractice: index});
    }

    @autobind
    handleToggleSwitch() {
        const {isSmsToggleOn} = this.state;
        const newToggleState = !isSmsToggleOn;
        this.setState({isSmsToggleOn: newToggleState}, () =>
            sessionStorageSetItem(IS_SWITCH_ON, newToggleState),
        );
    }

    render() {
        const {practices, selectedPractice, isRetired} = this.context;
        const {phoneNumber, isSmsToggleOn} = this.state;

        if (!practices?.length) {
            return null;
        }
        const profileId = this.context.id;
        const {
            bpData,
            fhirData,
            id,
            mdData,
            zmData,
            locationTelehealth,
            offersTelehealth,
        } = practices[selectedPractice];

        if (isRetired) {
            return null;
        }
        const telehealthOnly =
            offersTelehealth && locationTelehealth === TELEHEALTH_ONLY;

        const formattedPhoneNumber =
            phoneNumber &&
            ` <strong data-private>${formatAustralianMobileNumber(
                phoneNumber,
            )}</strong>`;

        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';

        const getCopyDataWithProfile = () => {
            const {FirstName, LastName, Title} = zmData;
            const profileName = [Title, FirstName, LastName]
                .filter((name) => name !== undefined && name !== null)
                .join(' ')
                .trim();
            return getCopyData(profileName, {
                ...practices[selectedPractice],
            });
        };

        return (
            <footer className={`write write-referral-footer pinned`}>
                {bpData && (
                    <BPWriteReferralButton
                        bpData={bpData}
                        clickTrackingData={{
                            eventAction: 'click',
                            eventCategory: EVENT_INTEGRATED_PROFILE,
                            eventLabel: `write referral - position ${
                                selectedPractice + 1
                            }`,
                        }}
                        eventData={{
                            practicePositionId: id,
                            practitionerId: profileId,
                        }}
                        telehealthOnly={telehealthOnly}
                        sendSMSFunc={
                            this.isValidMobileNumber && isSmsToggleOn
                                ? this.sendSMS
                                : null
                        }
                    />
                )}
                {mdData && (
                    <MDWriteReferralButton
                        clickTrackingData={{
                            eventAction: 'click',
                            eventCategory: EVENT_INTEGRATED_PROFILE,
                            eventLabel: `write referral - position ${
                                selectedPractice + 1
                            }`,
                        }}
                        eventData={{
                            practicePositionId: id,
                            practitionerId: profileId,
                        }}
                        mdData={mdData}
                        telehealthOnly={telehealthOnly}
                        sendSMSFunc={
                            this.isValidMobileNumber && isSmsToggleOn
                                ? this.sendSMS
                                : null
                        }
                    />
                )}
                {fhirData && (
                    <BPSecureMessageButton
                        customClass={'secondary'}
                        clickTrackingData={{
                            eventAction: 'click',
                            eventCategory: EVENT_INTEGRATED_PROFILE,
                            eventLabel: `secure message - position ${
                                selectedPractice + 1
                            }`,
                        }}
                        eventData={{
                            practicePositionId: id,
                            practitionerId: profileId,
                        }}
                        fhirData={fhirData}
                        telehealthOnly={telehealthOnly}
                        text={'Send eReferral'}
                        sendSMSFunc={
                            this.isValidMobileNumber && isSmsToggleOn
                                ? this.sendSMS
                                : null
                        }
                    />
                )}
                {zmData && showReferralBtn && (
                    <ZMWriteReferralButton
                        eventData={{
                            practicePositionId: id,
                            practitionerId: profileId,
                        }}
                        telehealthOnly={telehealthOnly}
                        zmData={zmData}
                        sendSMSFunc={
                            this.isValidMobileNumber && isSmsToggleOn
                                ? this.sendSMS
                                : null
                        }
                    />
                )}
                {zmData && !showReferralBtn && (
                    <HSReferralButton
                        eventData={{
                            eventName: 'copyPracticeDetails',
                            data: {
                                'practice_position': id,
                            },
                        }}
                        getCopyData={getCopyDataWithProfile}
                    />
                )}
                {this.isValidMobileNumber && (!zmData || showReferralBtn) && (
                    <Switch
                        customClass={`auto-sms reduced`}
                        onClick={this.handleToggleSwitch}
                        state={isSmsToggleOn ? 'on' : 'off'}
                        gaSelector={SEND_PROFILE_CHECKBOX}
                        text={SEND_SMS_TEXT + formattedPhoneNumber}
                        secondText={FREE_SMS_TEXT}
                    />
                )}
            </footer>
        );
    }
}
