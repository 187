import {observer} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';

import {ProfileStoreContext} from 'core/stores/RootStore.js';
import Tooltip from 'core/components/Tooltip.js';
import Icon from 'core/components/Icon.js';

export default
@observer
class NextAvailableBadge extends React.Component {
    static contextType = ProfileStoreContext;

    static propTypes = {
        hasIcon: PropTypes.bool,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                phone: PropTypes.shape({
                    number: PropTypes.string.isRequired,
                }),
            }),
        ).isRequired,
    };

    render() {
        const {
            getNextAvailableDate,
            getNextAvailableString,
            getNextAvailableVerificationString,
            isRetired,
        } = this.context;
        const nextAvailableDate = getNextAvailableDate(this.props.practices);

        if (!nextAvailableDate?.nextAvailable || isRetired) {
            return null;
        }

        return (
            <div className="next-available">
                <dt>{this.props.hasIcon && <Icon name="book" />}</dt>
                {getNextAvailableString(nextAvailableDate)}
                <Tooltip
                    content={getNextAvailableVerificationString(
                        nextAvailableDate.verified,
                    )}
                    defaultPosition="top"
                >
                    <Icon name="info" />
                </Tooltip>
            </div>
        );
    }
}
