import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import http from 'http.js';
import query from 'query.js';
import myHealthFirst from 'bookings/my_health_first.js';
import Button from 'core/components/Button.js';
import CallNowButton from 'core/components/CallNowButton.js';
import Dropdown from 'core/components/Dropdown.js';
import Icon from 'core/components/Icon.js';
import {
    PROFILE_CONTACT_TYPE,
    BOOK_NOW_BTN_REQ_PRIMARY,
    BOOK_NOW_BTN_MH1_PRIMARY,
    BOOK_NOW_BTN_MH1_LOCATION,
    ADD_TO_FAVOURITES_BTN,
    REMOVE_FROM_FAVOURITES_BTN,
    BP_FHIR,
} from 'core/constants.js';
import Link from 'core/components/Link';
import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import {
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
} from 'core/utils.js';
import BookNowButton from 'core/components/BookNowButton.js';
import {
    trackCustomBookNowButtonClick,
    trackCustomBookNowButtonImpression,
} from 'core/components/BookNowButton.js';
import ButtonGroup, {BUTTON_TYPE} from 'core/components/ButtonGroup.js';
import {getCopyData} from 'core/referrals.js';
import {openLinkInNewTab} from 'core/utils.js';
import {
    BPSecureMessageButton,
    BPWriteReferralButton,
    HSReferralButton,
    MDWriteReferralButton,
    MultipleReferralsButton,
} from 'professional/components/ReferralButtons.js';
import NextAvailableBadge from 'professional/components/NextAvailableBadge.js';

export const BOOKING_TYPE_INTEGRATION = 'integration';
export const BOOKING_TYPE_HEALTHSHARE = 'healthshare';
export const BOOKING_TYPE_NONE = 'no_bookings';

export default class HeaderButtonGroup extends React.Component {
    static propTypes = {
        bookingIntegration: PropTypes.shape({
            bookingId: PropTypes.bookingId,
            id: PropTypes.number,
            name: PropTypes.name,
            url: PropTypes.baseUrl,
        }),
        bookingUrl: PropTypes.string,
        claimUrl: PropTypes.string,
        client: PropTypes.string,
        customBookingDataUrl: PropTypes.string,
        displayName: PropTypes.string,
        hasSkin: PropTypes.bool,
        isEnhanced: PropTypes.bool.isRequired,
        isReferrals: PropTypes.bool.isRequired,
        pdfUrl: PropTypes.string,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                phones: PropTypes.arrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ),
            }),
        ).isRequired,
        profileId: PropTypes.number.isRequired,
        scheduleUrl: PropTypes.string,
        showBookAppointmentModal: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        showReferralModal: PropTypes.func.isRequired,
        specialistNowDomain: PropTypes.string,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            claimImpressionSent: false,
            isFavourite: false,
            isLoaded: false,
            hasIntegratedBookings: false,
            disableClaimButton: false,
        };
        this.favouritesUrl = '/api/professionals/v1/favourites/';
    }

    componentDidMount() {
        const {
            bookingIntegration,
            client,
            isReferrals,
            profileId,
        } = this.props;
        if (isReferrals && !client) {
            this.getFavourites();
        } else {
            this.setState({isLoaded: true});
        }

        if (this.shouldUpsellClaim()) {
            localStorageSetItem('claimprofilecampaign', profileId);
        }
        this.setHasIntegratedBookings();
        this.addBookingAnchors();
        if (this.bookingPractices?.length) {
            myHealthFirst.widgetSetup();
        }
    }

    async getFavourites() {
        const favourites = await http.get({
            url: this.favouritesUrl,
        });
        const isFavourite = !!favourites?.find(
            (favourite) => favourite.professional === this.props.profileId,
        );
        this.setState({isFavourite, isLoaded: true});
    }

    async addFavourites() {
        await http.post({
            url: this.favouritesUrl,
            data: {
                professional: this.props.profileId,
            },
        });
        this.setState({isFavourite: true});
    }

    async deleteFavourites() {
        await http.delete({
            url: `${this.favouritesUrl}${this.props.profileId}/`,
        });
        this.setState({isFavourite: false});
    }

    @autobind
    handleFavourites() {
        if (this.state.isFavourite) {
            this.deleteFavourites();
        } else {
            this.addFavourites();
        }
    }

    shouldUpsellClaim() {
        const {claimUrl, profileId} = this.props;
        const profileFromClaimCampaign = localStorageGetItem(
            'claimprofilecampaign',
        );
        return (
            claimUrl &&
            (parseInt(profileFromClaimCampaign) === profileId ||
                query.parse().source === 'claimprofilecampaign')
        );
    }

    setHasIntegratedBookings() {
        const practicesWithIntegratedBookings = this.bookingPractices.filter(
            (pr) => pr.bookingType === BOOKING_TYPE_INTEGRATION,
        );

        this.setState({
            hasIntegratedBookings:
                !!practicesWithIntegratedBookings.length ||
                !!this.props.bookingUrl,
        });
    }

    get clientLabel() {
        return this.props.client ? 'integrated' : 'non-integrated';
    }

    get integrationName() {
        const {bookingIntegration, bookingUrl, practices} = this.props;
        if (
            !this.state.hasIntegratedBookings &&
            this.bookingPractices.length
        ) {
            return 'healthshare';
        }
        if (bookingIntegration?.name) {
            return bookingIntegration.name;
        }
        const bookingPractice = practices.find(
            (pr) => pr?.bookingIntegration?.name,
        );
        if (bookingPractice) {
            return bookingPractice.bookingIntegration.name;
        }
        if (bookingUrl) {
            return 'Cliniko';
        }
        return null;
    }

    get profileHasBookings() {
        // check if at least one practice location has bookings
        return (
            this.props.practices.findIndex(
                (pr) =>
                    pr.bookingIntegration?.url || pr.requestAppointmentEmail,
            ) !== -1
        );
    }

    get bookingPractices() {
        let isReturnAllPractices = false;
        let isMH1Exist = false;
        // if only one practices
        if (this.props.practices?.length === 1) {
            return this.props.practices?.filter((pr) => {
                const customBookingDataUrl = pr.customBookingDataUrl;
                const requestAppointmentEmail = pr.requestAppointmentEmail;

                // if practice has a booking integration, prioritize
                if (pr?.bookingIntegration) {
                    pr.bookingType = BOOKING_TYPE_INTEGRATION;
                    return true;
                } else if (requestAppointmentEmail) {
                    pr.bookingType = BOOKING_TYPE_HEALTHSHARE;
                    return true;
                } else if (customBookingDataUrl) {
                    return true;
                }
                return false;
            });
        }

        // if 2 practices
        if (this.props.practices?.length > 1) {
            this.props.practices?.filter((practice) => {
                // if MH1 exist on of the the practice, display all the practices
                if (
                    practice?.bookingIntegration?.url
                        .toLowerCase()
                        .includes('myhealth1st')
                ) {
                    isReturnAllPractices = true;
                    isMH1Exist = true;
                }

                // if at least on requestAppointmentEmail exist, display all the practices
                if (practice?.requestAppointmentEmail) {
                    isReturnAllPractices = true;
                }
            });

            if (isReturnAllPractices) {
                const bookingPractices = this.props.practices.map(
                    (practice) => {
                        if (practice?.bookingIntegration) {
                            return {
                                ...practice,
                                bookingType: BOOKING_TYPE_INTEGRATION,
                            };
                        }

                        if (practice.requestAppointmentEmail) {
                            return {
                                ...practice,
                                bookingType: BOOKING_TYPE_HEALTHSHARE,
                            };
                        }

                        return {...practice, bookingType: BOOKING_TYPE_NONE};
                    },
                );
                return bookingPractices;
            }

            return this.props.practices?.filter((practice) => {
                if (practice?.bookingIntegration && !isMH1Exist) {
                    practice.bookingType = BOOKING_TYPE_INTEGRATION;
                    return true;
                }
                if (practice?.customBookingDataUrl) {
                    return true;
                }
                if (practice.requestAppointmentEmail) {
                    practice.bookingType = BOOKING_TYPE_HEALTHSHARE;
                    return true;
                }

                return false;
            });
        }

        return [];
    }

    @autobind
    handleBookingAnchorAction(bookingId) {
        const element = document.getElementById(bookingId);
        if (element) {
            element.click();
        }
    }

    @autobind
    handleRequestAppointment(practice) {
        this.props.showBookAppointmentModal(practice);
    }

    appendAnchor(bookingId, url) {
        const element = document.createElement('a');
        element.setAttribute('id', `${bookingId}`);
        element.setAttribute('class', '1stAvailableWidget2');
        element.setAttribute('data-mh1-source', 'healthshare');
        element.setAttribute('rel', 'nofollow noopener');
        element.setAttribute('href', url);
        document.body.appendChild(element);
    }

    @autobind
    addBookingAnchors() {
        this.bookingPractices.forEach((practice) => {
            if (practice.bookingType === BOOKING_TYPE_INTEGRATION) {
                if (practice?.bookingIntegration) {
                    this.appendAnchor(
                        practice?.bookingIntegration?.bookingId,
                        practice?.bookingIntegration?.url,
                    );
                }
            }
        });
    }

    get bookingDropdownOptions() {
        return [...this.bookingPractices].map((practice) => {
            let MH1Id = null;
            let otherBookingIntegrationId = null;
            let gaSelector = null;

            const isIntegration =
                practice.bookingType === BOOKING_TYPE_INTEGRATION;
            if (
                !isIntegration &&
                !practice.requestAppointmentEmail &&
                practice.customBookingDataUrl
            ) {
                trackCustomBookNowButtonImpression(
                    'practice position',
                    practice.id,
                );
            }

            if (practice?.bookingIntegration) {
                if (
                    practice?.bookingIntegration.url
                        .toLowerCase()
                        .includes('myhealth1st')
                ) {
                    MH1Id = practice?.bookingIntegration.bookingId;
                    gaSelector = BOOK_NOW_BTN_MH1_PRIMARY;
                }

                if (
                    practice?.bookingIntegration.url
                        .toLowerCase()
                        .includes('hotdoc') ||
                    practice?.bookingIntegration.url
                        .toLowerCase()
                        .includes('healthshareEngine')
                ) {
                    otherBookingIntegrationId =
                        practice?.bookingIntegration.url;
                }
            }

            return {
                name: practice.name,
                content: (
                    <Button
                        action={() => {
                            //MH1 booking integration
                            if (MH1Id) {
                                this.handleBookingAnchorAction(MH1Id);
                                return;
                            }
                            // customer booking url
                            if (practice.customBookingDataUrl) {
                                trackCustomBookNowButtonClick(
                                    'practice position',
                                    practice.id,
                                );
                                openLinkInNewTab(
                                    practice.customBookingDataUrl,
                                );
                                return;
                            }

                            // Hotdoc or healthEngine
                            if (otherBookingIntegrationId) {
                                openLinkInNewTab(otherBookingIntegrationId);
                                return;
                            }

                            this.handleRequestAppointment(practice);
                        }}
                        gaSelector={gaSelector}
                        customClass="1stAvailableWidget2"
                        text={
                            <>
                                <Icon name="mapPin" />
                                <h3>{practice.name}</h3>
                                <address>{practice.address}</address>
                            </>
                        }
                    ></Button>
                ),
            };
        });
    }

    renderBookingDropdown() {
        const {isReferrals} = this.props;
        if (isReferrals) {
            return null;
        }
        return (
            <Dropdown
                customClass="cta expand-cta"
                gaSelector={BOOK_NOW_BTN_REQ_PRIMARY}
                displayText="Book now"
                dropdownHeading={<h3>Choose a location</h3>}
                options={this.bookingDropdownOptions}
                optionsClassName={'location'}
            />
        );
    }

    get hasBookingIntegrationOnSinglePractice() {
        // handles if there is a single practice with both
        // an integrated and non integrated booking provider
        return (
            this.bookingPractices.length === 1 &&
            this.bookingPractices[0].bookingType === BOOKING_TYPE_INTEGRATION
        );
    }

    get bookingIntegrationType() {
        let bookingIntegration = null;

        if (
            this.bookingPractices[0]?.bookingIntegration?.url
                .toLowerCase()
                .includes('hotdoc') ||
            this.bookingPractices[0]?.bookingIntegration?.url
                .toLowerCase()
                .includes('healthshareEngine')
        ) {
            bookingIntegration = {
                ...bookingPractices[0]?.bookingIntegration,
                integration: 'other',
            };
        }

        this.bookingPractices?.map((practice) => {
            if (
                practice?.bookingIntegration?.url
                    .toLowerCase()
                    .includes('myhealth1st')
            ) {
                bookingIntegration = {
                    ...practice?.bookingIntegration,
                    integration: 'MH1',
                };
            }
        });
        return bookingIntegration;
    }

    renderBookingAction() {
        const {bookingUrl, hasSkin, isReferrals, profileId} = this.props;
        if (hasSkin) {
            return null;
        }

        //1. MH1 booking integration
        if (
            this.hasBookingIntegrationOnSinglePractice &&
            this.bookingIntegrationType?.integration === 'MH1'
        ) {
            const bookingId = this.bookingIntegrationType?.bookingId;
            return (
                <Button
                    action={() => this.handleBookingAnchorAction(bookingId)}
                    customClass="cta 1stAvailableWidget2"
                    text={'Book now'}
                />
            );
        }

        //2. nookal/cliniko
        if (
            bookingUrl &&
            !isReferrals &&
            this.bookingIntegrationType?.integration !== 'MH1'
        ) {
            return (
                <BookNowButton
                    objectType="practitioner"
                    objectId={profileId}
                    params={{practitioner: profileId}}
                />
            );
        }

        //3. CustomBookingDataUrl
        if (this.bookingPractices.length === 1) {
            const practice = this.bookingPractices[0];
            if (practice.customBookingDataUrl) {
                return (
                    <BookNowButton
                        action={() =>
                            openLinkInNewTab(practice.customBookingDataUrl)
                        }
                        objectType="practitioner"
                        objectId={profileId}
                        params={{practitioner: profileId}}
                    />
                );
            }
        }

        //4. HotDoc/HealthEngine
        if (
            this.hasBookingIntegrationOnSinglePractice &&
            this.bookingIntegrationType?.integration === 'other'
        ) {
            const url = this.bookingIntegrationType?.url;
            return (
                <Link
                    customClass="cta"
                    href={url}
                    isExternalLink={true}
                    rel={'nofollow'}
                >
                    {'Book now'}
                </Link>
            );
        }

        //5. RequestAppointment
        if (this.bookingPractices.length === 1) {
            const practice = this.bookingPractices[0];
            if (practice.requestAppointmentEmail) {
                return (
                    <Button
                        action={() =>
                            this.handleRequestAppointment(
                                this.bookingPractices[0],
                            )
                        }
                        customClass="cta"
                        gaSelector={BOOK_NOW_BTN_REQ_PRIMARY}
                        text={'Book now'}
                    />
                );
            }
        }

        // display drop down
        if (this.bookingPractices.length > 1) {
            return this.renderBookingDropdown();
        }
    }

    getCallNowButton() {
        const {client, practices, showModal} = this.props;
        if (!client && practices.some((practice) => practice.phones?.length)) {
            return (
                <CallNowButton
                    contactType={PROFILE_CONTACT_TYPE}
                    showModal={showModal}
                />
            );
        }
        return null;
    }
    @autobind
    removeClaimCampaignFromStorage() {
        this.setState({disableClaimButton: true});
        localStorageRemoveItem('claimprofilecampaign');
    }

    getClaimUpsellEventData(action) {
        return {
            eventAction: action,
            eventCategory: 'claim profile',
            eventLabel: `claim profile - emphasised - ${this.props.specialties[0].id}`,
        };
    }

    renderClaimUpsell() {
        const {claimUrl, displayName} = this.props;
        if (!this.state.claimImpressionSent) {
            this.setState({claimImpressionSent: true});
        }
        return (
            <Link
                action={this.removeClaimCampaignFromStorage}
                customClass={`claim-upsell ${
                    this.state.disableClaimButton ? 'disabled' : ''
                }`.trim()}
                href={claimUrl}
                title="Claim your HealthShare profile"
            >
                {displayName && `Are you ${displayName}?`}
                <strong>{'Claim your HealthShare profile'}</strong>
            </Link>
        );
    }

    renderReferralButtons(location) {
        const {client, displayName, isReferrals} = this.props;
        const {bpData, mdData, id, offersTelehealth} = location;
        const referralButtons = [];

        if (bpData || mdData) {
            if (bpData) {
                referralButtons.push({
                    buttonType: BUTTON_TYPE.REFERRAL,
                    component: (
                        <BPWriteReferralButton
                            bpData={bpData}
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'write referral',
                                eventLabel: 'header',
                            }}
                            eventData={{
                                practicePositionId: id,
                            }}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'write referral',
                                label: 'header',
                            }}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    ),
                });
                [];
            }
            if (mdData) {
                referralButtons.push({
                    buttonType: BUTTON_TYPE.REFERRAL,
                    component: (
                        <MDWriteReferralButton
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'write referral',
                                eventLabel: 'header',
                            }}
                            eventData={{
                                practicePositionId: id,
                            }}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'write referral',
                                label: 'header',
                            }}
                            mdData={mdData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    ),
                });
            }
            if (bpData && client === BP_FHIR) {
                referralButtons.push({
                    buttonType: BUTTON_TYPE.SECURE_MESSAGE,
                    component: (
                        <BPSecureMessageButton
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'secure message',
                                eventLabel: 'header',
                            }}
                            eventData={{
                                practicePositionId: id,
                            }}
                            fhirData={bpData}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'secure message',
                                label: 'header',
                            }}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    ),
                });
            }
        } else if (isReferrals) {
            const getCopyDataWithProfile = () => {
                return getCopyData(displayName, location);
            };
            referralButtons.push({
                buttonType: BUTTON_TYPE.REFERRAL,
                component: (
                    <HSReferralButton
                        clickTrackingData={{
                            eventAction: 'click',
                            eventCategory: 'copy referral',
                            eventLabel: 'header',
                        }}
                        eventData={{
                            eventName: 'copyPracticeDetails',
                            data: {
                                'practice_position': id,
                            },
                        }}
                        getCopyData={getCopyDataWithProfile}
                        impressionTrackingData={{
                            action: 'impression',
                            category: 'copy referral',
                            label: 'header',
                        }}
                    />
                ),
            });
        }

        return referralButtons;
    }

    renderMultipleReferralButtons() {
        const {client, isReferrals, practices, showReferralModal} = this.props;

        if (practices.length === 1) {
            return this.renderReferralButtons(practices[0]);
        }

        const clientData = practices.map((prac) => {
            return {
                bpData: prac.bpData,
                mdData: prac.mdData,
                referralEmail: prac.referralEmail,
            };
        });

        if (practices.length > 1) {
            const {displayName} = this.props;

            return [
                {
                    buttonType: BUTTON_TYPE.REFERRAL,
                    component: (
                        <MultipleReferralsButton
                            client={client}
                            clientData={clientData}
                            displayName={displayName}
                            getCopyData={getCopyData}
                            isReferrals={isReferrals}
                            locations={practices}
                            showModal={showReferralModal}
                        />
                    ),
                },
            ];
        }

        return [];
    }

    getProButtons() {
        const {isFavourite, isLoaded} = this.state;
        const {bookingUrl, isEnhanced} = this.props;
        let proButtons = [];

        const bookingButton = this.renderBookingAction();
        const callNowButton = this.getCallNowButton();

        if (bookingButton) {
            proButtons.push({
                buttonType: this.state.hasIntegratedBookings
                    ? BUTTON_TYPE.INTEGRATED_BOOK_NOW
                    : BUTTON_TYPE.BOOK_NOW,
                component: bookingButton,
            });
        }

        if (!this.props.client && isLoaded) {
            proButtons.push({
                buttonType: BUTTON_TYPE.FAVOURITE,
                component: (
                    <Button
                        action={this.handleFavourites}
                        customClass="cta"
                        gaSelector={
                            isFavourite
                                ? REMOVE_FROM_FAVOURITES_BTN
                                : ADD_TO_FAVOURITES_BTN
                        }
                        // eslint-disable-next-line max-len
                        text={
                            isFavourite
                                ? 'Remove from favourites'
                                : 'Add to favourites'
                        }
                    />
                ),
            });
        } else if (callNowButton) {
            proButtons.push({
                buttonType: BUTTON_TYPE.CALL_NOW,
                component: callNowButton,
            });
        }

        const referralButtons = this.renderMultipleReferralButtons();
        proButtons = [...proButtons, ...referralButtons];

        return proButtons;
    }

    getMemberButtons() {
        const {bookingUrl, isEnhanced} = this.props;
        const memberButtons = [];

        const bookingButton = this.renderBookingAction();
        const callNowButton = this.getCallNowButton();

        if (bookingButton) {
            memberButtons.push({
                buttonType: this.state.hasIntegratedBookings
                    ? BUTTON_TYPE.INTEGRATED_BOOK_NOW
                    : BUTTON_TYPE.BOOK_NOW,
                component: bookingButton,
            });
        }

        if (callNowButton) {
            memberButtons.push({
                buttonType: BUTTON_TYPE.CALL_NOW,
                component: callNowButton,
            });
        }

        return memberButtons;
    }

    renderButtonGroup() {
        const buttons = this.props.isReferrals
            ? this.getProButtons()
            : this.getMemberButtons();
        return <ButtonGroup buttons={buttons} />;
    }

    render() {
        return (
            <div className="button-group">
                {this.shouldUpsellClaim()
                    ? this.renderClaimUpsell()
                    : this.renderButtonGroup()}
                <NextAvailableBadge practices={this.props.practices} />
            </div>
        );
    }
}
