import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {ProfileStoreContext} from 'core/stores/RootStore.js';
import IntegratedProfileContent from 'professional/components/IntegratedProfileContent.js';
import IntegratedProfileSidebar from 'professional/components/IntegratedProfileSidebar.js';
import {PROFILE_TABS} from 'professional/stores/ProfileStore.js';
import Fees from 'professional/components/Fees.js';
import {REFERRALS} from 'core/constants.js';
export default
@observer
class IntegratedProfileApp extends React.Component {
    static contextType = ProfileStoreContext;

    static propTypes = {
        adData: PropTypes.shape({
            targeting: PropTypes.object.isRequired,
        }),
        avatar: PropTypes.string,
        awards: PropTypes.string,
        bio: PropTypes.string,
        bookingIntegration: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.name,
            url: PropTypes.baseUrl,
            bookingId: PropTypes.bookingId,
        }),
        bookingUrl: PropTypes.string,
        claimUrl: PropTypes.string,
        client: PropTypes.string,
        genderName: PropTypes.string.isRequired,
        globalPromotedGapSchemes: PropTypes.arrayOf(
            PropTypes.shape({
                gap_scheme_id: PropTypes.number, // eslint-disable-line camelcase
                health_fund_id: PropTypes.number, // eslint-disable-line camelcase
            }),
        ),
        initials: PropTypes.string,
        interests: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                specialty: PropTypes.number.isRequired,
            }),
        ).isRequired,
        isEnhanced: PropTypes.bool.isRequired,
        isGP: PropTypes.bool.isRequired,
        isRetired: PropTypes.bool.isRequired,
        isSpecialist: PropTypes.bool.isRequired,
        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
        offersTelehealth: PropTypes.bool,
        pdfUrl: PropTypes.string.isRequired,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                id: PropTypes.number,
                extraInfo: PropTypes.object,
                fax: PropTypes.shape({
                    number: PropTypes.string.isRequired,
                }),
                localityName: PropTypes.string,
                localityState: PropTypes.string,
                name: PropTypes.string,
                phones: PropTypes.arrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ),
                practiceUrl: PropTypes.string,
                services: PropTypes.array,
            }),
        ).isRequired,
        profileFeeComments: PropTypes.string,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        profileUrl: PropTypes.string.isRequired,
        promotedGapSchemes: PropTypes.arrayOf(
            PropTypes.shape({
                gapSchemeId: PropTypes.number,
                healthFundId: PropTypes.number,
            }),
        ),
        promotions: PropTypes.arrayOf(
            PropTypes.shape({
                healthFunds: PropTypes.arrayOf(PropTypes.number).isRequired,
                specialties: PropTypes.arrayOf(PropTypes.number).isRequired,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                order: PropTypes.number.isRequired,
            }),
        ),
        qualifications: PropTypes.string,
        qualificationsTitle: PropTypes.string,
        ratings: PropTypes.shape({
            bedsideManner: PropTypes.string.isRequired,
            conditionUnderstanding: PropTypes.string.isRequired,
            numberOfRatings: PropTypes.number.isRequired,
            rating: PropTypes.string.isRequired,
            treatmentCommunication: PropTypes.string.isRequired,
            wouldRecommend: PropTypes.number.isRequired,
        }),
        registrationNumbers: PropTypes.string,
        relatedInfo: PropTypes.object,
        scheduleUrl: PropTypes.string,
        showSpecialistNowBanner: PropTypes.bool.isRequired,
        specialInterests: PropTypes.string,
        specialistNowDomain: PropTypes.string.isRequired,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string,
                id: PropTypes.number,
                isSpecialist: PropTypes.bool,
                name: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        specialtyRegionTriggers: PropTypes.array.isRequired,
        staticUrl: PropTypes.string.isRequired,
        totalAgrees: PropTypes.number,
        totalContributions: PropTypes.number,
        totalThanks: PropTypes.number,
        userId: PropTypes.number.isRequired,
        userType: PropTypes.string,
        videos: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        // set feature class on body
        // TODO: remove after rollout is complete
        document.body.classList.add('integrated-profile');
    }

    async componentDidMount() {
        this.context.updateStore({isIntegratedFeature: true});
        this.context.rootStore.setupLogrocket();
    }

    renderMainContent() {
        const {
            activeTab,
            gapSchemeResults,
            gapSchemeResultsCount,
            localityId,
            specialtyId,
            updateSelectedFund,
        } = this.context;

        switch (activeTab) {
            case PROFILE_TABS.writeReferral:
                return <IntegratedProfileContent />;

            case PROFILE_TABS.fees:
                const feesProps = {
                    ...this.props,
                    gapSchemeResults,
                    gapSchemeResultsCount,
                    localityId,
                    specialtyId,
                    show: REFERRALS,
                    updateSelectedFund,
                };
                return (
                    <section className="fees">
                        <div>
                            <Fees {...feesProps} />
                        </div>
                    </section>
                );

            default:
                return <section />;
        }
    }

    render() {
        const {
            shouldRenderReturnToReferralsButton,
            renderReturnToReferralsButton,
        } = this.context;
        return (
            <>
                {shouldRenderReturnToReferralsButton &&
                    renderReturnToReferralsButton()}
                {this.renderMainContent()}
                <IntegratedProfileSidebar />
            </>
        );
    }
}
