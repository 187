import {observer} from 'mobx-react';
import React from 'react';

import IntegratedProfileHeader from 'professional/components/IntegratedProfileHeader.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import IntegratedProfilePracticeList from 'professional/components/IntegratedProfilePracticeList.js';
import IntegratedProfileReferralButtons from 'professional/components/IntegratedProfileReferralButtons.js';

@observer
export default class IntegratedProfileContent extends React.Component {
    static contextType = ProfileStoreContext;

    render() {
        const {practices} = this.context;
        return (
            <section>
                <IntegratedProfileHeader />
                <IntegratedProfilePracticeList practices={practices} />
                <IntegratedProfileReferralButtons />
            </section>
        );
    }
}
