import React from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';

import Avatar from 'core/components/Avatar.js';
import Icon from 'core/components/Icon.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import NextAvailableBadge from 'professional/components/NextAvailableBadge.js';

export default
@observer
class IntegratedProfileHeader extends React.Component {
    static contextType = ProfileStoreContext;

    renderGender() {
        const {genderName} = this.context;

        const hasDefinedGender =
            genderName && (genderName === 'Male' || genderName === 'Female');

        if (hasDefinedGender) {
            return (
                <>
                    <dt title="Gender">
                        <Icon name={`gender${genderName}`} />
                    </dt>
                    <dd title="Gender">{genderName}</dd>
                </>
            );
        }

        return null;
    }

    get languagesString() {
        const {languages} = this.context;

        if (!languages?.length) {
            return '';
        }

        return languages.reduce(
            (previous, current, idx) =>
                `${previous}${
                    idx === languages.length - 1 ? ' and ' : ', '
                }${current}`,
            ['Speaks English'],
        );
    }

    renderLanguages() {
        if (this.context.languages?.length) {
            return (
                <>
                    <dt title="Languages">
                        <Icon name="language" />
                    </dt>
                    <dd title="Languages">{this.languagesString}</dd>
                </>
            );
        }

        return null;
    }

    renderRetiredBadge() {
        const {isRetired} = this.context;
        if (isRetired) {
            return <span className="chip neutral">Retired</span>;
        }
        return null;
    }

    render() {
        const {
            avatar,
            initials,
            offersTelehealth,
            profileId,
            profileName,
        } = this.context;
        return (
            <header>
                {offersTelehealth && (
                    <span className="telehealth-badge">Telehealth</span>
                )}
                <Avatar
                    avatar={avatar}
                    displayName={profileName}
                    initials={initials}
                    profileId={profileId}
                />
                <h1>{profileName}</h1>
                <dl>
                    <NextAvailableBadge
                        hasIcon={true}
                        practices={toJS(this.context.practices)}
                    />
                    {this.renderGender()}
                    {this.renderLanguages()}
                    {this.renderRetiredBadge()}
                </dl>
            </header>
        );
    }
}
