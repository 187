import React from 'react';
import {observer} from 'mobx-react';

import Button from 'core/components/Button.js';
import CollapsibleText from 'core/components/CollapsibleText.js';
import Link from 'core/components/Link.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import {PROFILE_TABS} from 'professional/stores/ProfileStore.js';
import {EVENT_INTEGRATED_PROFILE} from 'core/constants.js';

export default
@observer
class IntegratedProfileSidebar extends React.Component {
    static contextType = ProfileStoreContext;

    renderSpecialties() {
        const {specialties} = this.context;
        if (specialties?.length) {
            return (
                <div>
                    <h2>{'Specialties'}</h2>
                    <>
                        {specialties
                            .map((specialty, idx) => (
                                <Link href={specialty.url} key={idx}>
                                    {specialty.displayName}
                                </Link>
                            ))
                            .reduce((previous, current) => [
                                previous,
                                ', ',
                                current,
                            ])}
                    </>
                </div>
            );
        }
        return null;
    }

    renderSpecialInterests() {
        const {specialInterests} = this.context;
        if (specialInterests) {
            return (
                <div>
                    <h2>{'Special interests'}</h2>
                    <CollapsibleText
                        expandText="Read more"
                        maxWords={10}
                        renderHtml={true}
                        text={specialInterests}
                    />
                </div>
            );
        }
        return null;
    }

    renderHospitals() {
        const {hospitals} = this.context;
        if (hospitals?.length) {
            return (
                <div>
                    <h2>{'Hospital affiliations'}</h2>
                    <ul>
                        {hospitals.map((hospital, idx) => (
                            <li key={idx}>
                                <Link href={hospital.url}>
                                    {hospital.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    }

    renderFees() {
        if (this.context.rootStore.healthFundStore?.healthFunds?.length) {
            return (
                <div>
                    <h2>{'Fees and health fund info'}</h2>
                    <Button
                        action={() =>
                            this.context.updateStore({
                                activeTab: PROFILE_TABS.fees,
                            })
                        }
                        customClass="cta"
                        icon="chevron"
                        text="See fees info"
                    />
                </div>
            );
        }
        return null;
    }

    renderBio() {
        const {bio} = this.context;
        if (bio) {
            return (
                <div>
                    <h2>{'Bio'}</h2>
                    <CollapsibleText
                        expandText="Read more"
                        maxWords={10}
                        renderHtml={true}
                        text={bio}
                    />
                </div>
            );
        }
        return null;
    }

    renderQualifications() {
        const {qualifications} = this.context;
        if (qualifications) {
            return (
                <div>
                    <h2>{'Qualifications'}</h2>
                    <CollapsibleText
                        expandText="Read more"
                        maxWords={10}
                        renderHtml={true}
                        text={qualifications}
                    />
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <section>
                {this.renderSpecialties()}
                {this.renderSpecialInterests()}
                {this.renderHospitals()}
                {this.renderFees()}
                {this.renderBio()}
                {this.renderQualifications()}
            </section>
        );
    }
}
